import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Unterstütze Browser",
  "description": "Mit welchen Browsern kann ich das inriva Karriereportal benutzen?",
  "author": "Jochen Ritscher",
  "categories": ["faqs"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false,
  "tags": ["browser"],
  "relatedArticles": ["how-to-install-gatsby-theme-helpsy"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Bitte verwenden Sie einen dieser Browser:`}</p>
    <p>{`Microsoft Edge (empfohlen): `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/de-de/edge"
      }}>{`https://www.microsoft.com/de-de/edge`}</a></p>
    <p>{`Google Chrome: `}<a parentName="p" {...{
        "href": "https://www.google.de/chrome"
      }}>{`https://www.google.de/chrome`}</a>{` `}</p>
    <p>{`Mozilla Firefox: `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/de/firefox"
      }}>{`https://www.mozilla.org/de/firefox`}</a>{` `}</p>
    <p>{`Apple Safari `}<a parentName="p" {...{
        "href": "https://www.apple.com/de/safari/"
      }}>{`https://www.apple.com/de/safari/`}</a></p>
    <p>{`Den Internet Explorer (IE) unterstützen wir nicht mehr, da das Produkt von Microsoft abgekündigt ist und aus Sicherheitsgründen von der Benutzung abgeraten wird.
Weitere Informationen:
`}<a parentName="p" {...{
        "href": "https://www.microsoft.com/de-de/microsoft-365/windows/end-of-ie-support"
      }}>{`https://www.microsoft.com/de-de/microsoft-365/windows/end-of-ie-support`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      